import React, {useState, useEffect, useRef} from 'react';
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { saveAs } from 'file-saver';

import Arrow from '../../components/Arrow.js';
import Loading from '../../components/Loading.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/live/MeaningfulWork.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function MeaningfulWork({cbPrevious, cbFinish, user, download}) {
  const [name, setName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [infoPopup, setInfoPopup] = useState(null);
  const [loading, setLoading] = useState(false);

  const [longArrowLen, setLongArrowLen] = useState(100);
  const longArrowContainerRef = useRef(null);
  const [data, setData] = useState({'driver-value': "", 'driver-desc': "", 'driver-altlabel': "", 'driver-altdesc': "", 'result-value': "", 'result-desc': "", 'result-alt': "", 'accelerator-values': "", 'mb-body': ""})  
  
  const year = (new Date()).getFullYear();

  const handleTextChange = (type, text) => {
    let newData = {...data};
    newData[type] = text;
    setData(newData);
  }

  const saveData = (next) => {
    let dataUpdate = {data: {}};
    for (let key in data) {
      dataUpdate.data[key] = data[key];
    }

    if (user) {
      dataUpdate['uid'] = user;
    }

    const url = `${API_ROUTE}/response/meaningfulwork`;

    axios.put(url, dataUpdate, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        if (next) {
          next();
        }
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };
  
  const downloadOutput = (filename) => {
    setLoading(true);
    axios.get(`${API_ROUTE}/event/event_output`, {
      withCredentials: true,
      params: new URLSearchParams({uid: user, page: 'mw'}),
      responseType: 'arraybuffer'
    }).then((res) => {
      const pdf = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdf, filename);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const resetResponses = () => {
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };

    axios.get(url, options).then((res) => {
      let values = res.data.values;
      let definitions = res.data.descriptions;
      let originalIndexes = res.data.originalIndexes;
      let percentLived = res.data.percentLived;

      console.log(res.data);

      let newData = {...data};
      newData['driver-value'] = values[0];
      newData['driver-desc'] = definitions[0];
      newData['driver-altlabel'] = "";
      newData['driver-altdesc'] = "";
      newData['result-value'] = values[9];
      newData['result-desc'] = definitions[9];
      newData['result-alt'] = "";
      newData['accelerator-values'] = `${values[5].toUpperCase()}\n\n↓\n${values[6].toUpperCase()}\n\n↓\n${values[7].toUpperCase()}\n\n↓\n${values[8].toUpperCase()}`;
      newData['mb-body'] = "";
      setData(newData);
    });
  };

  useEffect(() => {
    if (longArrowContainerRef.current) {
      setLongArrowLen(longArrowContainerRef.current.offsetHeight);
    }
  }, [longArrowContainerRef.current]);

  useEffect(() => {
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };
    axios.get(`${API_ROUTE}/response/meaningfulwork`, options).then((res) => {
      let newData = {...data};
      for (let key in data) {
        if (key in res.data.data) {
          newData[key] = res.data.data[key];
        }
      }
      setData(newData);
    }).catch((err) => {
      console.log(err);
      resetResponses();
    });

    const eventUrl = user ? `${API_ROUTE}/event/participant_event_byid` : `${API_ROUTE}/event/participant_event`;
    const eventOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({uid: user})
    } : {
      withCredentials: true
    };

    axios.get(eventUrl, eventOptions).then((res) => {
      let newEventName = res.data.event.eventName;
      let newEventDate = new Date(res.data.event.eventDate);

      setEventName(newEventName);
      setEventDate(newEventDate);
    });

    const userUrl = user ? `${API_ROUTE}/user/info_byid` : `${API_ROUTE}/user/info`;
    const userOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({uid: user})
    } : {
      withCredentials: true
    };

    axios.get(userUrl, userOptions).then((res) => {
      const newName = `${res.data.user.forename} ${res.data.user.surname}`;
      setName(newName);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <>
      <div>
        {loading && (
          <Loading />
        )}
      </div>
      {infoPopup && (infoPopup)}
      <div className="container-meaningfulwork" style={download && {margin: "16px 12px", padding: 0}}>
        <div className="container-meaningfulworkheader">
          <div>
            <img id="logo" className="logo" src="/images/PursuitLogo.png" alt="Pursuit Inc." />
          </div>
          <div className="container-header-title">
            <div className="title-name">
              My Meaningful Work
            </div>
          </div>
          <div className="container-header-title">
            <div className="date">
              [{eventName}/{eventDate.toDateString()}]
            </div>
          </div>
        </div>

        <div className="container-meaningfulworkcontent">
          <div className="container-meaningfulworkcontentleft">
            <div>
              <div className="header">Driver Value</div>
              <div className="subheader">"The Space I Hold..."</div>
            </div>
            <div>
              <div className="contain">
                <textarea className="editable value nonresizable"
                          type="text"
                          value={data['driver-value']}
                          onChange={(e) => handleTextChange("driver-value", e.target.value)}
                          placeholder=""
                          rows={1} />
                          
                <textarea className="editable description"
                          type="text"
                          value={data['driver-desc']}
                          onChange={(e) => handleTextChange("driver-desc", e.target.value)}
                          placeholder=""
                          rows={4} />
              </div>
              <div className="container-arrow">
                <Arrow length={50} angle={'180'} colour={'#d9d9d9'} />
                <div className="arrow-text">Through</div>
              </div>
            </div>

            <div>
              <div className="subheader">"The Way I Relate..."</div>
            </div>
            <div>
              <div className="contain">
                <textarea className="editable label nonresizable"
                          type="text"
                          value={data['driver-altlabel']}
                          onChange={(e) => handleTextChange("driver-altlabel", e.target.value)}
                          placeholder=""
                          rows={1} />
                <textarea className="editable description nonresizable"
                          type="text"
                          value={data['driver-altdesc']}
                          onChange={(e) => handleTextChange("driver-altdesc", e.target.value)}
                          placeholder=""
                          rows={1} />
              </div>
              <div className="container-arrow">
                <Arrow length={50} angle={'180'} colour={'#d9d9d9'} />
                <div className="arrow-text">Leads To</div>
              </div>
            </div>

            <div>
              <div className="header">Result Value</div>
              <div className="subheader">"The Possibilites That Inspire Me..."</div>
            </div>
            <div>
              <div className="contain">
                <textarea className="editable value nonresizable"
                          type="text"
                          value={data['result-value']}
                          onChange={(e) => handleTextChange("result-value", e.target.value)}
                          placeholder=""
                          rows={1} />
                          
                <textarea className="editable description"
                          type="text"
                          value={data['result-desc']}
                          onChange={(e) => handleTextChange("result-desc", e.target.value)}
                          placeholder=""
                          rows={4} />
              </div>
              
              <div className="container-arrow">
                <Arrow length={50} angle={'180'} colour={'#d9d9d9'} />
                <div className="arrow-text">Leads To</div>
              </div>
            </div>

            <div>
              <div className="subheader">"That Results In..."</div>
            </div>
            <div>
              <div className="contain">
                <textarea className="editable description"
                          type="text"
                          value={data['result-alt']}
                          onChange={(e) => handleTextChange("result-alt", e.target.value)}
                          placeholder=""
                          rows={4} />
              </div>
            </div>
          </div>

          <div className="container-meaningfulworkcontentarrows" ref={longArrowContainerRef}>
              <div className="container-arrow center-arrow">
                <Arrow length={longArrowLen*77.5/100} angle={'-15'} colour={'#d9d9d9'} widthScale={0.075} />
              </div>
              <div className="container-arrow left-arrow">
                <Arrow length={longArrowLen*3/4} angle={'0'} colour={'#e9e9e9'} widthScale={0.08} />
              </div>
          </div>
          
          <div className="container-meaningfulworkcontentright contain">
            <textarea className="editable description"
                            type="text"
                            // value={`${values[5]}\n\n↓\n${values[6]}\n\n↓\n${values[7]}\n\n↓\n${values[8]}`}
                            // value={`${values[5].toUpperCase()}\n\n↓\n${values[6].toUpperCase()}\n\n↓\n${values[7].toUpperCase()}\n\n↓\n${values[8].toUpperCase()}`}
                            value={data['accelerator-values']}
                            onChange={(e) => handleTextChange("accelerator-values", e.target.value)}
                            placeholder=""
                            rows={20} />
          </div>
          
          <div className="container-meaningfulworkcontentbottom">
            <div className="header">"Meaningful Because..."</div>
            <textarea className="editable description"
                            type="text"
                            value={data['mb-body']}
                            onChange={(e) => handleTextChange("mb-body", e.target.value)}
                            placeholder=""
                            rows={3} />
          </div>
        </div>

        {!download && (
          <div className="container-buttons">
            <button className="button-live" onClick={() => saveData(cbPrevious)}>Save & Previous</button>
            <button className="button-live" onClick={() => saveData(() => {})}>Save</button>
            <button className="button-live" onClick={() => downloadOutput(`MyMeanginfulWork-${name}-${eventName}-${eventDate.toDateString()}.pdf`)}>Download</button>
            <button className="button-live" onClick={() => saveData(cbFinish)}>Finish</button>
          </div>
        )}

        {download && (
          <div className="container-printfooter">
            <p>Copyright © 2007-{year} Pursuit Development Labs Inc. All Rights Reserved.</p>
          </div>
        )}
      </div>
    </>
  );
}

export default MeaningfulWork;
