import React, {useContext, useState, useEffect, useRef} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {AuthContext} from '../context/AuthContext.js';

import SessionHeader from '../components/SessionHeader.js';

import ToPrework from '../components/ToPrework.js';

// import Printable from '../components/Printable.js';

//Subpage imports
import Legal from '../subpages/live/Legal.js';
import Editing from '../subpages/live/Editing.js';
import Revealing from '../subpages/live/Revealing.js';
// import Output from '../subpages/live/Output.js';
import Output from '../subpages/live/Output2.js';
import MeaningfulWork from '../subpages/live/MeaningfulWork.js';

//Helper imports
import {getPageName, getPageInfo} from '../helpers/LiveHelpers.js';

//Static imports
import '../styles/Live.css';

const PAGE_COUNT = 5;

function Live() {
  const {logout} = useContext(AuthContext);
  const {uid} = useParams();
  const [pageNumber, setPageNumber] = useState(0);
  const [toPrework, setToPrework] = useState(false);

  let navigate = useNavigate();

  //Handle input functions
  const handleNextPage = () => {
    if (pageNumber + 1 < PAGE_COUNT) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber - 1 >= 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleToPrework = () => {
    setToPrework(true);
  }

  const handleReturnHome = () => {
    navigate('/');
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (uid && pageNumber < 1) {
      setPageNumber(1);
    }
  }, [uid]);

  //Rendering helper functionsx
  const renderSubPage = () => {
    switch(pageNumber) {
      case 0:
        return <Legal cbAgree={(e) => handleNextPage()} />;
      case 1:
        return <Editing cbNext={(e) => handleNextPage()}
                        cbPrevious={(e) => handlePreviousPage()}
                        cbToPrework={(e) => handleToPrework()}
                        user={uid}/>;
      case 2:
        return <Revealing cbNext={(e) => handleNextPage()}
                          cbPrevious={(e) => handlePreviousPage()}
                          user={uid}/>;
      case 3:
        return <Output cbPrevious={(e) => handlePreviousPage()}
                        cbNext={(e) => handleNextPage()}
                        cbFinish={uid ? (e) => handleBack() : (e) => handleLogout()}
                        user={uid} />;
      case 4:
        return <MeaningfulWork cbPrevious={(e) => handlePreviousPage()}
                                cbFinish={uid ? (e) => handleBack() : (e) => handleLogout()}
                                user={uid} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <SessionHeader pageName={getPageName(pageNumber)} pageInfo={getPageInfo(pageNumber)} />

      {toPrework && (
        <ToPrework uid={uid} />
      )}

      <div className="container-live">
        {renderSubPage()}
      </div>
    </>
  )
}

export default Live;
