import React from 'react';

const Arrow = ({ length, angle, colour, widthScale }) => {  
    const scaledWidth = length * (widthScale || 1);
    
    // colour = colour || 'black';
  
    return (
        <svg width={scaledWidth} height={length} style={{ transform: `rotate(${angle}deg)`}}>
          <line x1={scaledWidth / 2} y1={length} x2={scaledWidth / 2} y2={scaledWidth*1/4-2} style={{ stroke: colour, strokeWidth: scaledWidth/4 }} />
          {/* <polygon points={`${length}, ${length*3/4},${scaledWidth / 2 - scaledWidth / 4} ${length*3/4},${scaledWidth / 2 + scaledWidth / 4}`} style={{ fill: colour }} /> */}
          <polygon points={`${scaledWidth / 2}, 0, ${scaledWidth / 2 - scaledWidth / 4}, ${scaledWidth*1/4} ${scaledWidth / 2 + scaledWidth / 4}, ${scaledWidth*1/4}`} style={{ fill: colour }} />
        </svg>
    );
  };

export default Arrow;